$primeng-info-colour: #b3e5fc;

@use 'sass:math';
@use 'mixins';
@use 'variables';

.truncate {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

// App-specific theme overrides

.p-toast.p-toast-top-right {
    // Default top: 20px;
    top: calc(#{variables.$ta-layout-navbar-top-height} + 20px);
}

.phone-number-error-wrapper {
    &--modal {
        .p-inline-message.p-inline-message-error {
            margin-top: variables.$tatheme-layout-spacing-xsmall;
        }
    }
}

// Overrides for changing the icons in p-message from PrimeIcons to our own ta icons, since the p-message component does not have an "icon" attribute, as well as changing colours. Reasoning for this and more information can be found in this video by a member of the PrimeNG team: https://www.youtube.com/watch?v=3MF63kxFHjo
@mixin use-ta-icon($icon) {
    &::before {
        content: $icon;
    }
}

// Also alter the style of p-messages component. The reason for this is to be able to add links into a p-message, for example a link for users to upgrade their subscription. The reason why we need to use the p-messages component rather than p-message is
// because the template of p-messages can be overridden to add in new elements, such as an <a> tag. GUIDE: Use <p-messages> element when extra elements need to be added to the message component, such as links, use <p-message> element for everything else.
// For more information about the <p-messages> component, see this link: https://primefaces.org/primeng/messages
.p-messages {
    .p-message {
        margin: variables.$tatheme-layout-spacing-medium 0 0;

        a {
            cursor: pointer;
            text-decoration: underline;
            font-weight: 600;
        }

        i {
            margin-right: variables.$tatheme-layout-spacing-xsmall;
        }

        &.p-message-warn i {
            color: variables.$warningMessageIconColor;
        }

        &.p-message-error i {
            color: variables.$errorMessageIconColor;
        }
    }
}

// Put certain error messages onto a new line
p-message.newline {
    .p-inline-message {
        margin: variables.$tatheme-layout-spacing-xsmall 0 0;
    }
}

p-message.no-margin {
    .p-inline-message {
        margin: 0;
    }
}

// Custom p-message
p-message.custom-blue .p-inline-message {
    background-color: variables.$lightBlue95 !important;
}

// Error messages in Assessments should spread to be the width of their whole container.
p-message.assessment {
    .p-inline-message {
        width: 100%;
        justify-content: flex-start;
    }
}

p-message.extra-margin {
    .p-inline-message {
        margin: variables.$tatheme-layout-spacing-medium 0 0 0;
    }
}

.remove-link-style {
    color: inherit;
    text-decoration: inherit;
}

.p-column-filter-overlay,
.p-overlaypanel {
    .p-listbox {
        border: none;
    }
}

.p-column-filter-overlay-menu .p-column-filter-constraints .p-column-filter-constraint {
    padding: variables.$tatheme-layout-spacing-small 0 0;
}

.p-listbox .p-listbox-list .p-listbox-item {
    padding: variables.$tatheme-layout-spacing-xsmall 1rem !important;

    &.p-highlight {
        background: variables.$lightGreen85 !important;
    }
}

// Need to add an extra styleClass filter here to make it so that other elements using p-overlaypanel do not have their padding changed
.filter.p-overlaypanel .p-overlaypanel-content {
    padding: variables.$tatheme-layout-spacing-small 0 0;
}

.p-input-icon-left {
    .ta {
        font-size: 1rem;
        z-index: 2;
    }

    .p-autocomplete {
        width: 100%;

        .p-inputtext {
            padding-left: 30px;
            width: 100%;
        }
    }
}

.admin-checkbox {
    .p-checkbox-label {
        font-size: variables.$tatheme-typography-body-medium;
        font-weight: variables.$tatheme-typography-weight-semibold;
        padding-left: 70px;
        margin-top: variables.$tatheme-layout-spacing-xsmall;
        margin-bottom: variables.$tatheme-layout-spacing-xsmall;
        min-width: 20px;
    }
}

.taskalyser-override {
    &--large {
        .p-dropdown {
            width: 100%;
        }
    }

    &--small {
        .p-dropdown {
            width: 175px;
        }
    }

    &--xsmall {
        .p-dropdown {
            width: 125px;
        }
    }

    &--medium {
        .p-dropdown {
            width: 50.5%;
        }
    }
}

.p-tag {
    &.p-tag-danger {
        background: variables.$lightRed95 !important;
        border: variables.$tatheme-border-light solid variables.$lightRed45 !important;
        border-radius: 2px !important;
        color: variables.$darkRed30 !important;
        padding: 0 !important;
    }

    &.p-tag-warning {
        background: variables.$lightYellow95 !important;
        border: variables.$tatheme-border-light solid variables.$darkYellow55 !important;
        border-radius: 2px !important;
        color: variables.$darkYellow45 !important;
        padding: 0 !important;
    }

    &.p-tag-success {
        background: variables.$lightGreen95 !important;
        border: variables.$tatheme-border-light solid variables.$lightGreen35 !important;
        border-radius: 2px !important;
        color: variables.$darkGreen15 !important;
        padding: 0 !important;
    }

    &.p-tag-info {
        background: variables.$lightPurple95 !important;
        border: variables.$tatheme-border-light solid variables.$primaryPurple35 !important;
        border-radius: 2px !important;
        color: variables.$primaryPurple35 !important;
        padding: 0 !important;
    }

    &-value {
        font-size: 10px !important;
        padding: variables.$tatheme-layout-spacing-xxsmall variables.$tatheme-layout-spacing-xsmall 2px variables.$tatheme-layout-spacing-xsmall !important;
        font-weight: variables.$tatheme-typography-weight-semibold !important;
    }

    &__received {
        .p-tag {
            background: variables.$lightBlue95 !important;
            border: variables.$tatheme-border-light solid variables.$lightBlue60 !important;
            border-radius: 2px !important;
            color: variables.$lightBlue35 !important;
            padding: 0 !important;
        }
    }

    &__failed {
        .p-tag {
            background: none !important;
            border: variables.$tatheme-border-light solid variables.$primaryRed35 !important;
            border-radius: 2px !important;
            color: variables.$lightRed45 !important;
            padding: 0 !important;
        }
    }
}

// Custom p-tags
p-tag.custom-grey .p-tag {
    background: variables.$lightGrey95 !important;
    border: variables.$tatheme-border-light solid variables.$lightGrey60 !important;
    border-radius: 2px !important;
    color: variables.$primaryGrey40 !important;
    padding: 0 !important;
}

.p-datatable {
    .p-component-overlay {
        background-color: #ffffff9f;
    }
}

.p-input-icon-left > .ta {
    font-size: 1rem;
}

// custom mega menu overrides for insights page
.assessment-insights {
    .p-megamenu {
        border: unset !important;
        background: unset !important;
        padding: unset;

        .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
            display: flex;
            justify-content: space-between;
            border: variables.$tatheme-border-light solid variables.$lightGrey80;
            border-radius: 2px;
            padding: variables.$tatheme-layout-spacing-xsmall;
            width: 290px;
        }

        .p-megamenu-submenu {
            width: unset;

            .p-megamenu-submenu-header {
                color: variables.$darkGrey30;
            }
        }

        .p-megamenu-panel {
            width: 100%;
        }
    }
}

/* override stepper */
.normal-steps {
    $step-margin: 1rem;
    $step-width: 2rem;

    &.p-steps {
        margin-left: -12.5%;
        width: 125%;

        .p-steps-item {
            opacity: 1 !important;

            .p-menuitem-link {
                &:not(.p-disabled):focus {
                    box-shadow: none;
                }

                .p-steps-number {
                    color: variables.$lightGrey60;
                    margin: $step-margin;
                    padding-top: 2px;
                    font-size: variables.$tatheme-typography-body-medium;
                    font-weight: variables.$tatheme-typography-weight-semibold;
                    border: 2px solid variables.$lightGrey90;
                }

                .p-steps-title {
                    white-space: break-spaces;
                    color: variables.$lightGrey60;
                    font-weight: variables.$tatheme-typography-weight-semibold;
                    margin-top: 0;
                    font-size: variables.$tatheme-typography-body-medium;
                }
            }

            &::before {
                border-top: 2px solid variables.$lightGrey90;
                height: 0;
                top: 59%;
            }

            &:first-child {
                &::before {
                    margin-left: 50%;
                    width: 50%;
                }
            }

            &:last-child {
                &::before {
                    width: 50%;
                }
            }

            &.p-highlight {
                .p-steps-number {
                    background-color: variables.$ta-colour-primary-light;
                    color: variables.$ta-colour-neutrals-white;
                    border: variables.$lightGrey90;
                }

                .p-steps-title {
                    color: variables.$ta-colour-primary-standard;
                }
            }
        }
    }
}

/* override stepper to make it vertical */
.vertical-steps {
    $step-margin: 1rem;
    $step-width: 2rem;

    &.p-steps {
        ul {
            display: block;
            padding-top: variables.$tatheme-layout-spacing-medium;
        }

        .p-steps-item {
            justify-content: left;
            margin-left: variables.$tatheme-layout-spacing-medium;

            .p-menuitem-link {
                flex-direction: row;
                padding-right: 1rem;
                overflow-x: hidden !important;
                cursor: pointer;

                &:not(.p-disabled):focus {
                    box-shadow: inset 0 0 0 1px variables.$ta-colour-primary-light;
                }

                .p-steps-number {
                    color: variables.$lightGrey50;
                    margin: $step-margin;
                    padding-bottom: 2px;
                    font-size: variables.$tatheme-typography-body-medium;
                    font-weight: variables.$tatheme-typography-weight-semibold;
                    border: 2px solid variables.$lightGrey90;
                }

                .p-steps-title {
                    margin-top: 0;
                    white-space: break-spaces;
                    color: variables.$lightGrey50;
                    font-weight: variables.$tatheme-typography-weight-semibold;
                }
            }

            &::before {
                border-left: 2px solid variables.$lightGrey90;
                width: 0;
                height: 100%;
                top: 0;
                left: (math.div($step-width, 2) + $step-margin);
            }

            &:first-child {
                &::before {
                    top: 50%;
                }
            }

            &:last-child {
                &::before {
                    height: 60%;
                }
            }

            &.p-highlight {
                .p-steps-number {
                    background-color: variables.$ta-colour-primary-light;
                    color: variables.$ta-colour-neutrals-white;
                    border: variables.$ta-colour-primary-light;
                }

                .p-steps-title {
                    color: variables.$lightGreen35;
                }
            }
        }
    }
}

/* override panel */
.p-panel-no-border {
    .border-0 {
        border: 0 !important;
    }

    .p-panel {
        .p-panel-header {
            @extend .border-0;

            padding: variables.$tatheme-layout-spacing-medium variables.$tatheme-layout-spacing-small variables.$tatheme-layout-spacing-small 0;
            background: none;
            justify-content: flex-start;
        }

        .p-panel-title {
            @extend .border-0;
        }

        .p-panel-content {
            @extend .border-0;

            padding: 0 0 variables.$tatheme-layout-spacing-medium 40px;
            width: 100%;
        }

        .p-panel-footer {
            @extend .border-0;

            padding-left: 40px;
        }
    }
}

.assessments-error-message-wrapper {
    .p-inline-message.p-inline-message-error {
        margin: 0;
        width: 100% !important;
        justify-content: flex-start !important;
        margin-bottom: variables.$tatheme-layout-spacing-xsmall;
    }
}

.assessments-error-warning-wrapper {
    .p-inline-message.p-inline-message-warn {
        margin: 0;
        width: 100% !important;
        justify-content: flex-start !important;
        margin-bottom: variables.$tatheme-layout-spacing-xsmall;
    }
}

.assessments-info-warning-wrapper {
    .p-inline-message.p-inline-message-info {
        margin: 0;
        background: $primeng-info-colour;
    }
}

p {
    line-height: 1.3;
}

/**
* avatar with green background and white text.
*/
.p-avatar-ta {
    background: variables.$ta-colour-primary-light !important;
    color: variables.$ta-colour-neutrals-white !important;

    &.p-avatar-ta {
        &--medium {
            font-size: variables.$tatheme-typography-body-medium !important;

            .p-avatar-text {
                font-size: inherit !important;
            }
        }
    }
}

/**
  deal with chips overrides.
*/
.p-chips {
    ul.p-chips-multiple-container {
        padding-top: variables.$tatheme-layout-spacing-xsmall;
        padding-bottom: variables.$tatheme-layout-spacing-xsmall;

        li.p-chips-token {
            background: variables.$lightGrey90;
            color: variables.$darkGrey25;
        }
    }
}

.invite-users-model {
    .p-chips {
        width: 100%;

        .p-chips-multiple-container {
            width: 100%;
        }
    }
}

/**
  Solves an issue where the scroll bar of p-scrollpanel was displaying over our acrylic overlays.
*/
.p-scrollpanel.p-component {
    .p-scrollpanel-wrapper {
        z-index: unset;
    }
}

.admin-permissions-edit-component-container {
    .p-dropdown {
        width: 150px;
    }
}

/**
  Global text and padding and margin related things
*/
.text {
    &--xxsmall-m-t {
        margin-top: variables.$tatheme-layout-spacing-xxsmall !important;
    }

    &--small-m-t {
        margin-top: variables.$tatheme-layout-spacing-small !important;
    }

    &--subtle {
        font-size: variables.$tatheme-typography-body-extra-small;
    }

    &--obvious {
        font-weight: variables.$tatheme-typography-weight-semibold;
    }

    &--green {
        color: variables.$ta-colour-primary-standard;
    }

    &--red {
        color: variables.$primaryRed35;
    }

    &--italic {
        font-style: italic;
    }

    &--lightGrey70 {
        color: variables.$lightGrey70;
    }

    &--primaryGrey {
        color: variables.$ta-colour-neutrals-standard;
    }
}

.p-dialog {
    .p-dialog-content {
        display: flex;

        .ta {
            font-size: 1.75rem;
            height: 1.75rem;
            margin-right: variables.$tatheme-layout-spacing-small;
            margin-top: auto;
            margin-bottom: auto;
        }

        p {
            margin-top: 0.4rem !important;
            line-height: 1.4rem;

            &.margin-0 {
                margin: 0 !important;
            }
        }
    }

    .p-dialog-footer {
        .p-button {
            margin-left: variables.$tatheme-layout-spacing-small;
            margin-right: 0;
        }
    }
}

// Custom checkbox styles to make native checkboxes look like the PrimeNG checkboxes
input[type='checkbox'] {
    // Get rid of the default checkbox
    appearance: none;

    // For iOS < 15 to remove gradient background
    background-color: variables.$white;

    // Not removed via appearance
    margin: 0;

    // For centering the checkbox primeicon we place in the ::before pseudo-element
    display: inline-flex;
    align-items: center;
    justify-content: center;

    // Set the size to be the same as p-checkbox
    min-width: 20px;
    min-height: 20px;

    // Checkbox styles that are in p-checkbox
    cursor: pointer;
    border: 1px solid variables.$lightGrey70;
    border-radius: 2px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

    &:focus {
        box-shadow: inset 0 0 0 1px variables.$ta-colour-primary-standard;
        border-color: variables.$ta-colour-primary-standard;
        outline: 0 none;
        outline-offset: 0;
    }

    &:hover:not(:disabled) {
        border-color: variables.$themeSecondary;
    }

    &::before {
        // When the checkbox is not checked, don't display an icon
        font-family: primeicons, sans-serif;
        content: '';
    }

    &:checked {
        color: variables.$white;
        background-color: variables.$ta-colour-primary-standard;
        border-color: variables.$ta-colour-primary-standard;

        &:hover:not(:disabled) {
            border-color: variables.$themeDark;
            background-color: variables.$themeDark;
        }

        &::before {
            // Display the icon when the checkbox is checked
            content: '\e909';
        }
    }

    &:disabled {
        cursor: default;
        pointer-events: none;

        &:not(:checked) {
            background-color: variables.$lightGrey93;
            border-color: variables.$lightGrey70;
        }

        &:checked {
            opacity: 0.6;
        }
    }
}

/**
* Form field styling
*/
.form-field {
    display: block;
    margin-bottom: variables.$tatheme-layout-spacing-medium;

    &.form-field {
        &--small {
            margin-bottom: variables.$tatheme-layout-spacing-small !important;
        }

        &--medium {
            margin-bottom: variables.$tatheme-layout-spacing-medium !important;
        }

        &--large {
            margin-bottom: variables.$tatheme-layout-spacing-large !important;
        }

        &--divider {
            border-bottom: 1px solid variables.$lightGrey90;
            margin-top: variables.$tatheme-layout-spacing-small + variables.$tatheme-layout-spacing-large;
        }
    }

    .label {
        color: variables.$darkGrey30;
        font-family: variables.$fontFamily !important;
        display: block;
        font-weight: variables.$tatheme-typography-weight-semibold;
        margin-bottom: variables.$tatheme-layout-spacing-xsmall;
        font-size: variables.$tatheme-typography-body-medium;

        &.label {
            &--has-description {
                margin: 0;
            }

            &--normal {
                font-weight: variables.$tatheme-typography-weight-regular !important;
                margin-bottom: 0 !important;
                font-size: variables.$tatheme-typography-body-small !important;
            }

            &--large {
                font-size: variables.$tatheme-typography-body-large !important;
            }
        }
    }

    // For when we need to put another element on the same line as a label
    .label-container {
        display: flex;
        align-items: center;

        // For a label with a subtitle aligned to the right of the label (eg to indicate that a field is optional)
        &--with-subtitle {
            justify-content: space-between;

            span {
                color: variables.$lightGrey50;
                font-size: variables.$tatheme-typography-body-extra-small;
                margin-bottom: variables.$tatheme-layout-spacing-xsmall;
            }
        }

        // For a label with a tooltip icon to the right that the user can hover over for more information
        &--with-icon {
            justify-content: flex-start;

            i {
                margin: 0 0 variables.$tatheme-layout-spacing-xsmall variables.$tatheme-layout-spacing-xsmall;
            }
        }
    }

    .description {
        font-size: variables.$tatheme-typography-body-extra-small;
    }

    .form-element {
        margin-bottom: variables.$tatheme-layout-spacing-xsmall;

        &--last {
            margin: 0;
        }

        &--comment {
            width: 100%;
        }
    }
}

.ta-flex {
    display: flex !important;
    font-family: variables.$fontFamily !important;

    &.ta-flex {
        &--space-between {
            justify-content: space-between;
        }

        &__direction {
            &--column {
                flex-direction: row;
            }

            &--row {
                flex-direction: row;
            }
        }

        &__align-center {
            align-items: center;
        }
    }
}

.p-progressbar {
    height: 8px !important;
}

.ta-table-wrapper {
    height: calc(100vh - #{variables.$ta-layout-navbar-top-height} - #{variables.$ta-layout-page-header-height});
}

.table__body__item,
.table__header__item {
    /* one of the global theme styles sets anything with the prefix ta- to have line-height: 1 which makes the text in the tables to sometimes
    be cut off. */
    line-height: 1.5;
}

a.link {
    color: variables.$primaryGreen30;
    font-weight: variables.$tatheme-typography-weight-semibold;
    text-decoration: none;
}

// Remove transition for faster visual feedback from disabled to enabled state
.p-button.p-button-danger.p-button-text {
    transition: none;
}

// Make the number of entries per page in the paginator line up with the dropdown icon
.p-paginator .p-dropdown .p-dropdown-label {
    line-height: 1.3;
}

.ngx-charts-tooltip-content {
    * .tooltip-content:has(* .series-tooltip-custom) {
        margin-bottom: variables.$tatheme-layout-spacing-small;
    }
}

.assessment-insights-person-forecast-movement-frequency-component,
.assessment-insights-person-forecast-range-of-motion-component {
    .p-dropdown {
        width: 100%;
    }
}
