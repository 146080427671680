@use 'variables';
// import any app wide styling here
@import './app/shared/styles/taskalyser.styles';

html,
body {
    height: 100%;
    overflow-y: hidden;
}
body {
    margin: 0;
}
